import { createStore } from 'vuex'

// Store modules
import authModule from './modules/auth'
import dbModule from './modules/db'
import iamModule from './modules/iam'
import router from '@/router'
import billingModule from './modules/billing'

export default createStore({
  state: {
    user: {} as any,
    org: {} as any,
    project: {} as any,
    run: {} as any,

    serviceGroup: {} as any,
    resource: {} as any,

    orgErr: null as any,
    projectErr: null as any,
    runErr: null as any,

    showCreateOrg: false,
    showCreateCIProject: false,
    showLocalRunSetup: false,
    showCISetup: false,
    showTerraformCloudSetup: false,
    showSetup: false,
    showExportDiagram: null,

    setupShown: true,

    roles: [
      { name: "admin", iconName: "", iconColor: "" },
      { name: "maintainer", iconName: "", iconColor: "" },
      { name: "viewer", iconName: "", iconColor: "" }
    ]
  },
  mutations: {
    updateUser(state: any, user: any) { state.user = user },
    updateOrg(state: any, org: any) { state.org = org },
    updateProject(state: any, project: any) { state.project = project },
    updateRun(state: any, run: any) { state.run = run },

    updateServiceGroup(state: any, serviceGroup: any) { state.serviceGroup = serviceGroup },
    updateResource(state: any, resource: any) { state.resource = resource },

    showCreateOrg(state: any, show: boolean) { state.showCreateOrg = show },
    showCreateCIProject(state: any, show: boolean) { state.showCreateCIProject = show },
    showLocalRunSetup(state: any, show: boolean) { state.showLocalRunSetup = show },
    showCISetup(state: any, show: boolean) { state.showCISetup = show },
    showTerraformCloudSetup(state: any, show: boolean) { state.showTerraformCloudSetup = show },
    showSetup(state: any, show: boolean) { state.showSetup = show },
    setupShown(state: any, show: boolean) { state.setupShown = show },
    showExportDiagram(state: any, format: boolean) { state.showExportDiagram = format },

    setOrgErr(state: any, orgErr: any) { state.orgErr = orgErr },
    setProjectErr(state: any, projectErr: any) { state.projectErr = projectErr },
    setRunErr(state: any, runErr: any) { state.runErr = runErr },

    // Org updates
    addOrgInvite(state: any, invite: any) { state.org.invites[invite.email.replaceAll('.', '_')] = invite },
    revokeOrgInvite(state: any, email: string) { delete state.org.invites[`${email.replaceAll('.', '_')}`] },
    removeOrgUser(state: any, { orgId, userId }: { orgId: number, userId: string }) { delete state.org.users[`${userId}`] },
    renameOrg(state: any, name: string) { 
      state.org.name = name
      state.user.orgs[state.org.id].name = name
    },
    cacheOrgProjects(state: any, projects: any) { 
      let cachedProjects = {} as any
      projects.forEach((project: any) => cachedProjects[project.id] = project)
      state.org.projects = cachedProjects
    },

    // Project updates
    addProjectInvite(state: any, invite: any) { state.project.invites[invite.email.replaceAll('.', '_')] = invite },
    revokeProjectInvite(state: any, email: string) { delete state.project.invites[`${email.replaceAll('.', '_')}`] },
    addProjectUser(state: any, user: any) { state.project.users[user.id] = user },
    removeProjectUser(state: any, userId: string) { delete state.project.users[`${userId}`] },
    renameProject(state: any, name: string) { 
      state.project.name = name
      state.user.projects[state.project.id].name = name
    },
    clearProject(state: any) { state.project = {} },
    updateSelectedBranch(state: any, branch: string) { state.project.selectedBranch = branch },
    updateBranchRuns(state: any, runs: any[]) { state.project.runs[state.project.selectedBranch] = runs },
    loadOlderBranchRuns(state: any, runs: any[]) { state.project.runs[state.project.selectedBranch].push(...runs) },

    // Run updates
    deleteRun(state: any, run: any) {
      let branchRuns = state.project.runs[run.branch]
      let targetIndex = branchRuns.findIndex((target: any) => `${target.id}` === `${run.id}`)
      branchRuns.splice(targetIndex, 1)
    },

    // User updates
    addUserProject(state: any, project: any) { state.user.projects[project.id] = project },
    removeUserProject(state: any, projectId: number) {
      delete state.user.projects[`${projectId}`]
      router.push(`/orgs/${state.org.id}/`)
    },
    removeUserOrg(state: any, orgId: number) { delete state.user.orgs[`${orgId}`] },

    // Connector updates
    updateConnector(state: any, connector: any) {
      if (!state.project.connectors) state.project['connectors'] = { [connector.id]: connector }
      else state.project.connectors[connector.id] = connector
    },
    deleteConnector(state: any, connectorId: any) { delete state.project.connectors[connectorId] },

    clearStore(state: any) { // For sign out
      state.user = {},
      state.org = {},
      state.project = {},
      state.run = {},
      state.orgErr = null,
      state.projectErr = null,
      state.runErr = null
    }
  },
  getters: {
    appIcons: () => { return require('@/assets/appIcons.json') },
    providerIcons: () => { return require('@/assets/providerIcons.json') },
    setupShown: (state) => { return state.setupShown}
  },
  actions: {},
  modules: {
    auth: authModule,
    db: dbModule,
    iam: iamModule,
    billing: billingModule
  }
})
