const RunRoutes = [
  {
    path: '',
    name: 'project-runs',
    component: () => import(/* webpackChunkName: "run" */ '@/views/Runs/RunOverview.vue'),
  },
  {
    path: ':runId',
    name: 'run',
    component: () => import(/* webpackChunkName: "run" */ '@/views/Run.vue'),
    children: [
      {
        path: 'changes',
        name: 'run-changes',
        component: () => import(/* webpackChunkName: "run" */ '@/views/Runs/RunDetail.vue'),
      },
      {
        path: 'costs',
        name: 'run-costs',
        component: () => import(/* webpackChunkName: "run" */ '@/views/Runs/RunDetail.vue'),
      },
      {
        path: 'view',
        name: 'run-view',
        component: () => import(/* webpackChunkName: "run" */ '@/views/Runs/RunDetail.vue'),
      },
    ]
  }
]

export default RunRoutes