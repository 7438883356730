import RunRoutes from './run'

const ProjectRoutes = [
  {
    path: 'runs',
    name: 'project-runs',
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project/ProjectRuns.vue'),
    children: RunRoutes
  },
  {
    path: 'iam',
    name: 'project-iam',
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project/ProjectIAM.vue'),
  },
  {
    path: 'settings',
    name: 'project-settings',
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project/ProjectSettings.vue'),
  },
]

export default ProjectRoutes