import router from "@/router"
import axios from "axios"

const dbModule = {
  state: {},
  actions: {
    // - - - ORG - - -
    async getOrg({ rootState, commit }: { rootState: any, commit: Function }, orgId: number) {
      try {
        let orgDoc = await axios({
          url: `https://api.pluralith.com/v1/org/get?orgId=${orgId}`, 
          // url: `http://localhost:8080/v1/org/get?orgId=${orgId}`, 
          method: 'GET',
          headers: { authorization: "Bearer " + rootState.user.apiKey }
        })

        let orgData = orgDoc.data.data

        commit('updateOrg', orgData)
        commit('setOrgErr', null)
        console.log('loaded org data for: ', orgId)
      } catch(error: any) {
        commit('setOrgErr', { id: `${orgId}`, dataType: 'org', code: error.code || 'An Error Ocurred' })
      }
    },

    async createOrg({ rootState, dispatch }: { rootState: any, dispatch: Function }, orgData: any) {
      try {
        await axios({
          // url: 'http://localhost:8080/v1/org/create',
          url: 'https://api.pluralith.com/v1/org/create',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: orgData
        })

        // Get updated user object with new org added
        dispatch('getUser')
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },

    async renameOrg({ rootState, commit }: { rootState: any, commit: Function }, name: string) {
      try {
        let response = await axios({
          // url: 'http://localhost:8080/v1/org/rename',
          url: 'https://api.pluralith.com/v1/org/rename',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            name: name,
            orgId: `${rootState.org.id}`
          }
        })

        if (response.data.code < 400) commit('renameOrg', name) // Rename org in frontend
        return response.data.code
      } catch(error: any) {
        console.error(error.response)
        return 400
      }
    },

    async updateCurrentOrg({ rootState }: { rootState: any }, orgId: number) {
      try {
        let response = await axios({
          url: 'https://api.pluralith.com/v1/user/update',
          // url: 'http://localhost:8080/v1/user/update',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: { id: `${rootState.user.id}`, currentOrg: orgId }
        })

        router.push(`/orgs/${orgId}/`)
        console.log('switched to org: ', orgId)
        return response.data.code
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },

    async deleteOrg({ rootState, dispatch, commit }: { rootState: any, dispatch: Function, commit: Function }) {
      try {
        let response = await axios({
          // url: 'http://localhost:8080/v1/org/delete',
          url: 'https://api.pluralith.com/v1/org/delete',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: { 
            orgId: `${rootState.org.id}`
          }
        })

        if (response.data.code < 400) {
          // Remove org from local user object
          commit('removeUserOrg', `${rootState.org.id}`)

          // Switch to personal
          let personalOrg: any = Object.values(rootState.user.orgs).find((org: any) => org.name === 'Personal')
          await dispatch('getOrg', `${personalOrg.id}`)
          await dispatch('updateCurrentOrg', `${personalOrg.id}`)
        }

        return response.data.code
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },

    // - - - PROJECT - - - 
    async getProject({ rootState, commit }: { rootState: any, commit: Function }, projectId: any) {
      try {
        let projectDoc = await axios({
          url: `https://api.pluralith.com/v1/project/get?projectId=${projectId}&orgId=${rootState.org.id}`, 
          // url: `http://localhost:8080/v1/project/get?projectId=${projectId}&orgId=${rootState.org.id}`, 
          method: 'GET',
          headers: { authorization: "Bearer " + rootState.user.apiKey }
        })

        let projectData = projectDoc.data.data

        // Initialize run object to cache fetched runs under their according branches
        projectData.runs = {}
        if (projectData.branches) {
          Object.keys(projectData.branches).forEach((branch: string) => projectData.runs[branch] = [])
          
          // Select default branch
          if (projectData.branches['main']) projectData.selectedBranch = 'main'
          else if (projectData.branches['master']) projectData.selectedBranch = 'master'
          else projectData.selectedBranch = Object.keys(projectData.branches)[0]
        }


        commit('updateProject', projectData)
        commit('setProjectErr', null)
        console.log('loaded project data for: ', projectId)
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        commit('setProjectErr', { id: `${projectId}`, dataType: 'project', code: error.code || 'An Error Ocurred' })
      }
    },

    async createProject({ rootState }: { rootState: any }, projectData: any) {
      try {
        // Create project
        let response = await axios({
          url: 'https://api.pluralith.com/v1/project/create',
          // url: 'http://localhost:8080/v1/project/create',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: projectData
        })

        // Update local user object
        rootState.user.projects[projectData.id] = {
          id: `${projectData.id}`,
          name: projectData.name,
          orgId: `${projectData.orgId}`,
          providers: [],
          lastUpdate: Date.now(),
          role: 'admin'
        }

        return response.data.code
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },

    async renameProject({ rootState, commit }: { rootState: any, commit: Function }, name: string) {
      try {
        let response = await axios({
          url: 'https://api.pluralith.com/v1/project/rename',
          // url: 'http://localhost:8080/v1/project/rename',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            name: name,
            orgId: `${rootState.org.id}`,
            projectId: `${rootState.project.id}`
          }
        })

        if (response.data.code < 400) commit('renameProject', name) // Rename org in frontend
        return response.data.code
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },

    async deleteProject({ rootState }: { rootState: any }) {
      try {
        let response = await axios({
          url: 'https://api.pluralith.com/v1/project/delete',
          // url: 'http://localhost:8080/v1/project/delete',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: { orgId: `${rootState.org.id}`, projectId: `${rootState.project.id}` }
        })
        
        return response.data.code
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },

    // - - - CONNECTOR - - -
    async updateConnector({ rootState, commit }: { rootState: any, commit: Function }, connectorPayload: any) {
      try {
        let response = await axios({
          // url: `http://localhost:8080/v1/docs/update`,
          url: `https://api.pluralith.com/v1/docs/update`,
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: connectorPayload
        })

        commit('updateConnector', connectorPayload.connectorData)

        return response.status
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },
    async deleteConnector({ rootState, commit }: { rootState: any, commit: Function }, connectorPayload: any) {
      try {
        let response = await axios({
          // url: `http://localhost:8080/v1/docs/delete`,
          url: `https://api.pluralith.com/v1/docs/delete`,
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: connectorPayload
        })

        commit('deleteConnector', connectorPayload.connectorId)

        return response.status
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },

    // - - - RUN - - -
    async getProjectRuns({ rootState, commit }: { rootState: any, commit: Function }, { branch, startAfter }: { branch: string, startAfter: number | null }) {
      try {
        let url = `https://api.pluralith.com/v1/project/get-runs?projectId=${rootState.project.id}&orgId=${rootState.org.id}&branch=${branch}`
        url = startAfter ? url + `&startAfter=${startAfter}` : url

        let response = await axios({
          url: url, 
          method: 'GET',
          headers: { authorization: "Bearer " + rootState.user.apiKey }
        })

        let projectRunData = response.data.data

        projectRunData.forEach((run: any) => {
          if (run.graph.costs.totalMonthlyCost) run.graph.costs['diffPercentage'] = run.graph.costs.pastTotalMonthlyCost === 0 ? 100 : Math.round((run.graph.costs.totalMonthlyCost / run.graph.costs.pastTotalMonthlyCost - 1) * 100)
        })

        // Load older runs or completely reload run list depending on startAfter timestamp given or not
        startAfter ? commit('loadOlderBranchRuns', projectRunData) : commit('updateBranchRuns', projectRunData)
        return response.data.code
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },
    async getRun({ rootState, commit }: { rootState: any, commit: Function }, runId: string) {
      try {
        let response = await axios({
          url: `https://api.pluralith.com/v1/run/get?runId=${runId}&projectId=${rootState.project.id}&orgId=${rootState.org.id}`, 
          // url: `http://localhost:8080/v1/run/get?runId=${runId}&projectId=${rootState.project.id}&orgId=${rootState.org.id}`, 
          method: 'GET',
          headers: { authorization: "Bearer " + rootState.user.apiKey }
        })

        let runData = response.data.data

        if (!runData) throw { code: `No run #${runId} on this project` }

        commit('updateRun', runData)
        commit('setRunErr', null)
        console.log('loaded run data for: ', runId)
        return response.data.code
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        commit('setRunErr', { id: `${runId}`, dataType: 'run', code: error.code || 'An Error Ocurred' })
        return 400
      }
    },
    async exportDiagram({ rootState }: { rootState: any }, config: any) {
      try {
        let response = await axios({
          // url: 'http://localhost:8080/v1/run/export',
          url: 'https://api.pluralith.com/v1/run/export',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            ...rootState.run,
            config: config
          }
        })

        return response.data
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    },
    async deleteRun({ commit, rootState }: { commit: Function, rootState: any }, run: any) {
      try {
        let response = await axios({
          url: 'https://api.pluralith.com/v1/run/delete',
          // url: 'http://localhost:8080/v1/run/delete',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            orgId: rootState.org.id,
            projectId: rootState.project.id,
            runId: run.id
          }
        })

        // Update local run list
        commit('deleteRun', run)

        return response.data.code
      } catch(error: any) {
        console.error(error)
        return 400
      }
    },

    // - - - RESOURCE - - -
    async getResource({ rootState, commit }: { rootState: any, commit: Function }, inventoryId: string) {
      try {
        let response = await axios({
          url: `https://api.pluralith.com/v1/resource/get?inventoryId=${inventoryId}&projectId=${rootState.project.id}&orgId=${rootState.org.id}`,
          // url: `http://localhost:8080/v1/resource/get?inventoryId=${inventoryId}&projectId=${rootState.project.id}&orgId=${rootState.org.id}`,
          method: 'GET',
          headers: { authorization: "Bearer " + rootState.user.apiKey }
        })

        return response.data.data
      } catch(error: any) {
        if (error.response) console.error(error.response)
        else console.error(error)
        return 400
      }
    }
  }
}

export default dbModule