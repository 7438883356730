import { initializeApp } from 'firebase/app';
// import { getFirestore, collection } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'

import firebaseConfig from './config';
let app = initializeApp(firebaseConfig);

const pluralithAuth = getAuth(app);
// const pluralithStore = getFirestore(app);

// const pluralithUsers = collection(pluralithStore, 'users');
// const pluralithOrgs = collection(pluralithStore, 'orgs');
// const pluralithInvites = collection(pluralithStore, 'invites');
// const pluralithProjects = collection(pluralithStore, 'projects');
// const pluralithRuns = collection(pluralithStore, 'runs');

export {
  pluralithAuth,
  // pluralithStore,

  // pluralithUsers,
  // pluralithOrgs,
  // pluralithInvites,
  // pluralithProjects,
  // pluralithRuns
}