import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, getIdToken, UserCredential, sendPasswordResetEmail } from 'firebase/auth';
import axios from 'axios'

import router from '@/router';
import { pluralithAuth } from '@/firebase/firebase';

const authModule = {
  state: {
    authStatus: 'none' as 'none' | 'running' | 'success' | 'failure',
    authError: ''
  },
  mutations: {
    updateAuthStatus(state: any, status: any) { state.authStatus = status },
    updateAuthError(state: any, error: any) { state.authError = error }
  },
  actions: {
    async signIn({ state, commit }: { state: any, commit: Function }, form: any) {
      try {
        commit('setupShown', false)
        commit('updateAuthStatus', 'running')

        await signInWithEmailAndPassword(pluralithAuth, form.email, form.password)
        .catch((error: any) => {
          commit('updateAuthStatus', 'failure')
          commit('updateAuthError', error.code)
        }) as UserCredential

        if (state.authStatus !== 'failure') {
          commit('updateAuthStatus', 'success')

          if (router.currentRoute.value.query.redirect) {
            router.push({ path: '/loading', query: { redirect: router.currentRoute.value.query.redirect } })
          } else {
            router.push({ path: '/loading' })
          }
        }
      } catch(error) {
        commit('updateAuthStatus', 'failure')
        console.log('sign in error: ', error)
      }
    },

    async signUp({ rootState, dispatch, commit }: { rootState: any, dispatch: Function, commit: Function }, form: any) {
      try {
        commit('setupShown', false)
        commit('updateAuthStatus', 'running')

        const credential = await createUserWithEmailAndPassword(pluralithAuth, form.email, form.password).catch((error) => {
          commit('updateAuthStatus', 'failure')
          commit('updateAuthError', error.code)
        }) as UserCredential
        const personalOrgId = Math.floor(Math.random() * 1000000000)
  
        if (rootState.authStatus !== 'failure') {
          let userData = {
            id: `${credential.user.uid}`,
            name: form.name,
            email: form.email,
            signupDate: Date.now(),
            orgs: {},
            projects: {},
            invites: {},
            currentOrg: `${personalOrgId}`
          }

          let orgData = {
            name: 'Personal',
            id: `${personalOrgId}`,
            users: {
              [credential.user.uid]: {
                id: `${credential.user.uid}`,
                name: form.name,
                email: form.email,
                role: 'admin',
              }
            },
            invites: {},
            icon: 'hexagon',
            plan: {
              tier: 'free',
              seats: 1
            },
            personal: true
          }

          let token = await getIdToken(pluralithAuth.currentUser!) // No API key defined at this point -> Stick with firebase token for now
          await axios({
            url: 'https://api.pluralith.com/v1/user/create/', 
            // url: 'http://localhost:8080/v1/user/create/', 
            method: 'POST',
            headers: { authorization: "Bearer " + token },
            data: userData
          })

          await axios({
            url: 'https://api.pluralith.com/v1/org/create/', 
            // url: 'http://localhost:8080/v1/org/create/', 
            method: 'POST',
            headers: { authorization: "Bearer " + token },
            data: orgData
          })

          // Create local runs project
          let projectData = {
            name: 'Local Runs',
            id: 'pluralith-local-project',
            orgId: `${personalOrgId}`
          }

          await axios({
            url: 'https://api.pluralith.com/v1/project/create/', 
            // url: 'http://localhost:8080/v1/org/create/', 
            method: 'POST',
            headers: { authorization: "Bearer " + token },
            data: projectData
          })

          // Generate API key and get full user object again
          await dispatch('generateAPIKey')
          await dispatch('getUser')
  
          commit('updateAuthStatus', 'success')
          if (router.currentRoute.value.query.redirect) {
            router.push({ path: '/loading', query: { redirect: router.currentRoute.value.query.redirect } })
          } else {
            router.push({ path: '/loading' })
          }
        }
      } catch(error) {
        commit('updateAuthStatus', 'failure')
        console.log('sign up error: ', error)
      }
    },

    async resetPassword({ commit }: { commit: Function }, email: string) {
      try {
        await sendPasswordResetEmail(pluralithAuth, email)
        .then(() => {
          commit('updateAuthStatus', 'success')
        })
        .catch((error: any) => {
          commit('updateAuthStatus', 'failure')
          commit('updateAuthError', error.code)
        })
      } catch(error: any) {
        commit('updateAuthStatus', 'failure')
        console.log('reset password error: ', error)
      }
    },

    async signOut({ commit }: { commit: Function }) {
      await signOut(pluralithAuth)
      router.push({ path: '/auth' })

      setTimeout(() => commit('clearStore', {}), 500)
    },

    async generateAPIKey({}) {
      try {
        let token = await getIdToken(pluralithAuth.currentUser!)
        await axios({
          // url: "http://localhost:8080/v1/auth/key/generate",
          url: "https://api.pluralith.com/v1/auth/key/generate",
          method: 'POST',
          headers: { authorization: "Bearer " + token },
          data: { id: pluralithAuth.currentUser!.uid }
        })
      } catch(error: any) {
        console.error(error)
      }
    },

    async getUser({ commit }: { commit: Function }) {
      // pluralithAuth.currentUser!.uid -> Replace with proper id stored in mongo on self-hosted
      try {
        let token = await getIdToken(pluralithAuth.currentUser!)
        let userDoc = await axios({
          url: `https://api.pluralith.com/v1/user/get?userId=${pluralithAuth.currentUser!.uid}`, 
          // url: `http://localhost:8080/v1/user/get?userId=${pluralithAuth.currentUser!.uid}`, 
          method: 'GET',
          headers: { authorization: "Bearer " + token }
        })

        let userData = userDoc.data

        if (userData.data) commit('updateUser', userData.data)
      } catch(error: any) {
        console.log(error)
      }
    },
  },
  getters: {}
}

export default authModule