import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { isEmpty } from 'lodash'

import OrgRoutes from './subrouters/org'
import { pluralithAuth } from '@/firebase/firebase'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "base" */ '../views/Auth.vue')
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import(/* webpackChunkName: "base" */ '../views/Invite.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import(/* webpackChunkName: "base" */ '../views/Loading.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'orgs/:orgId/',
        name: 'org',
        component: () => import(/* webpackChunkName: "org" */ '../views/Org.vue'),
        children: OrgRoutes
      },
      {
        path: '/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "user" */ '../views/Org.vue'),
        children: [
          {
            path: 'settings',
            name: 'user-settings',
            component: () => import(/* webpackChunkName: "user-settings" */ '../views/User/UserSettings.vue'),
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, _, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  // Checking if user authenticated
  if (requiresAuth && !pluralithAuth.currentUser && to.name !== 'auth') {
    next({ path: '/auth', query: { redirect: to.fullPath } })
  } else if (pluralithAuth.currentUser && isEmpty(store.state.user) && to.name !== 'loading') {
    next({ path: '/loading', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router
