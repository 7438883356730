import ProjectRoutes from './project';

const OrgRoutes = [
  {
    path: '',
    name: 'org-projects',
    component: () => import(/* webpackChunkName: "org" */ '@/views/Org/OrgProjects.vue'),
  },
  {
    path: 'settings',
    name: 'org-settings',
    component: () => import(/* webpackChunkName: "org" */ '@/views/Org/OrgSettings.vue'),
  },
  {
    path: 'iam',
    name: 'org-iam',
    component: () => import(/* webpackChunkName: "org" */ '@/views/Org/OrgIAM.vue'),
  },
  {
    path: 'billing',
    name: 'org-billing',
    component: () => import(/* webpackChunkName: "org" */ '@/views/Org/OrgBilling.vue'),
  },
  {
    path: 'projects/:projectId',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project.vue'),
    children: ProjectRoutes
  }
]

export default OrgRoutes