import axios from "axios"
import { cloneDeep } from "lodash"

const iamModule = {
  state: {},
  actions: {
    // - - - INVITES - - -
    async inviteUser({ commit, dispatch }: { commit: Function, dispatch: Function }, { data, mode }: { data: any, mode: 'org' | 'project'}) {
      let status = await dispatch('createInvite', { data: data, mode: mode })

      // Update local invites
      commit('addOrgInvite', cloneDeep(data))
      if (mode === 'project') commit('addProjectInvite', cloneDeep(data))

      return status
    },
    async getInvite({ rootState }: { rootState: any }, inviteId: string) {
      try {
        let inviteDoc = await axios({
          url: `https://api.pluralith.com/v1/iam/invite/get?inviteId=${inviteId}`, 
          // url: `http://localhost:8080/v1/iam/invite/get?inviteId=${inviteId}`, 
          method: 'GET',
          headers: { authorization: "Bearer " + rootState.user.apiKey }
        })

        let inviteData = inviteDoc.data.data

        return inviteData
      } catch(error: any) {
        console.error(error)
        return null
      }
    },
    async createInvite({ rootState }: { rootState: any }, { data, mode }: { data: any, mode: 'org' | 'project' }) {
      try {
        let response = await axios({
          // url: 'http://localhost:8080/v1/iam/invite/create',
          url: 'https://api.pluralith.com/v1/iam/invite/create',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            id: `${data.inviteId}`,
            user: data,
            mode: mode,
            org: {
              id: `${rootState.org.id}`,
              icon: rootState.org.icon,
              name: rootState.org.name,
              members: Object.keys(rootState.org.users).length,
              role: mode === 'org' ? data.role : 'viewer' // If mode is project -> Set org role to default 'viewer'
            },
            project: mode === 'org' ? null : { // If mode is project -> Attach project data
              id: `${rootState.project.id}`,
              lastUpdate: null,
              name: rootState.project.name,
              orgId: `${rootState.project.orgId}`,
              role: data.role,
              providers: rootState.project.providers
            }
          }
        })

        return response.data.code
      } catch(error: any) {
        console.error(error)
        return 400
      }
    },
    async acceptInvite({ rootState }: { rootState: any }, invite: any) {
      try {
        // Add more user info necessary to add user to orgs/projects
        invite.user['id'] = `${rootState.user.id}`
        invite.user['name'] = rootState.user.name

        let response = await axios({
          // url: 'http://localhost:8080/v1/iam/invite/accept',
          url: 'https://api.pluralith.com/v1/iam/invite/accept',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            ...invite,
            mode: invite.project ? 'project' : 'org'
          }
        })

        return response.data.code
      } catch(error: any) {
        console.error(error)
        return 400
      }
    },
    async revokeInvite({ rootState }: { rootState: any }, invite: any) {
      try {
        let response = await axios({
          // url: 'http://localhost:8080/v1/iam/invite/revoke',
          url: 'https://api.pluralith.com/v1/iam/invite/revoke',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            mode: rootState.project.id ? 'project' : 'org',
            orgId: `${rootState.org.id}`,
            projectId: rootState.project.id ? `${rootState.project.id}` : null,
            invite: invite
          }
        })

        return response.data.code
      } catch(error: any) {
        console.error(error)
        return 400
      }
    },
    async updateInviteRole({ rootState }: { rootState: any }, invite: any) {
      try {
        let response = await axios({
          // url: 'http://localhost:8080/v1/iam/invite/update-role',
          url: 'https://api.pluralith.com/v1/iam/invite/update-role',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            mode: rootState.project.id ? 'project' : 'org',
            orgId: `${rootState.org.id}`,
            projectId: rootState.project.id ? `${rootState.project.id}` : null,
            invite: invite
          }
        })

        return response.data.code

      } catch(error: any) {
        console.error(error)
        return 400
      }
    },
    async resendInviteMail({ rootState }: { rootState: any }, { invite, mode }: { invite: any, mode: 'org' | 'project'}) {
      try {
        let response = await axios({
          // url: 'http://localhost:8080/v1/iam/invite/resend',
          url: 'https://api.pluralith.com/v1/iam/invite/resend',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: { 
            mode: mode,
            orgId: `${rootState.org.id}`,
            projectId: rootState.project.id ? `${rootState.project.id}` : null,
            invite: invite
          }
        })

        return response.data.code
      } catch(error: any) {
        console.error(error)
        return 400
      }
    },

    // - - - MANAGEMENT - - -
    async addOrgCandidate({ commit, dispatch, rootState }: { commit: Function, dispatch: Function, rootState: any }, { user, role }: { user: any, role: string }) {
      try {
        let userData = {
          id: `${user.id}`,
          name: user.name,
          email: user.email,
          role: role
        }
        let projectData = {
          id: `${rootState.project.id}`,
          lastUpdate: null,
          name: rootState.project.name,
          orgId: `${rootState.org.id}`,
          role: role,
          providers: rootState.project.providers
        }
  
        let response = await axios({
          // url: 'http://localhost:8080/v1/iam/manage/add-org-user',
          url: 'https://api.pluralith.com/v1/iam/manage/add-org-user',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            user: userData,
            project: projectData,
          }
        })
  
        // Update local project users
        commit('addProjectUser', cloneDeep({ ...user, role: role }))
  
        return response.data.code
      } catch(error: any) {
        console.error(error.response)
        return 400
      }
    },
    async updateUserRole({ commit, rootState }: { commit: Function, rootState: any }, user: any) {//{ user, mode }: { user: any, role: string, mode: 'org' | 'project'}) {
      try {
        let response = await axios({
          url: 'https://api.pluralith.com/v1/iam/manage/update-role',
          // url: 'http://localhost:8080/v1/iam/manage/update-role',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            mode: rootState.project.id ? 'project' : 'org',
            orgId: `${rootState.org.id}`,
            projectId: `${rootState.project.id}`,
            user: user
          } 
        })

        return response.data.code
      } catch(error: any) {
        console.error(error.response)
        return 400
      }
    },
    async removeUser({ commit, dispatch, rootState }: { commit: Function, dispatch: Function, rootState: any }, user: any) {
      try {
        let response = await axios({
          url: 'https://api.pluralith.com/v1/iam/manage/remove',
          // url: 'http://localhost:8080/v1/iam/manage/remove',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            mode: rootState.project.id ? 'project' : 'org',
            orgId: `${rootState.org.id}`,
            projectId: rootState.project.id ? `${rootState.project.id}` : null,
            userId: user.id
          }
        })

        // Update local users
        // ORG
        if (!rootState.project.id) {
          if (user.id === rootState.user.id) {
            let personalOrg: any = Object.values(rootState.user.orgs).find((org: any) => org.name === 'Personal')
            
            await dispatch('getOrg', `${personalOrg.id}`)
            await dispatch('updateCurrentOrg', `${personalOrg.id}`)

            commit('removeUserOrg', `${rootState.org.id}`)
          }

          commit('removeOrgUser', { orgId: `${rootState.org.id}`, userId: `${user.id}` })
        }

        // PROJECT
        if (rootState.project.id) {
          commit('removeProjectUser', user.id)
          if (user.id === rootState.user.id) commit('removeUserProject', `${rootState.project.id}`)
        }

        return response.data.code
      } catch(error: any) {
        console.error(error)
        return 400
      }
    },

    // Admin Methods
    async getOrgProjects({ rootState, commit }: { rootState: any, commit: Function }) {
      try {
        let response = await axios({
          url: `https://api.pluralith.com/v1/org/get-projects?orgId=${rootState.org.id}`, 
          // url: `http://localhost:8080/v1/org/get-projects?orgId=${rootState.org.id}`, 
          method: 'GET',
          headers: { authorization: "Bearer " + rootState.user.apiKey }
        })

        let orgProjects = response.data.data

        commit('cacheOrgProjects', orgProjects)
        return response.data.code
      } catch(error: any) {
        console.error(error)
        return error
      }
    },
    async joinOrgProject({ rootState, commit }: { rootState: any, commit: Function }) {
      try {
        let userData = {
          id: `${rootState.user.id}`,
          name: rootState.user.name,
          email: rootState.user.email,
          role: 'admin'
        }
        let projectData = {
          id: `${rootState.project.id}`,
          lastUpdate: null,
          name: rootState.project.name,
          orgId: `${rootState.org.id}`,
          role: 'admin',
          providers: rootState.project.providers
        }

        let response = await axios({
          // url: 'http://localhost:8080/v1/iam/manage/add-org-user',
          url: 'https://api.pluralith.com/v1/iam/manage/add-org-user',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            user: userData,
            project: projectData
          }
        })

        // Update local project users
        commit('addProjectUser', userData)
        commit('addUserProject', projectData)

        return response.data.code
      } catch(error: any) {
        console.error(error)
        return error
      }
    },
  }
}

export default iamModule