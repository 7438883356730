import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './firebase/firebase';
import { pluralithAuth } from './firebase/firebase';

let app: any;
pluralithAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .use(store)
      .mount('#app')
  }
})
