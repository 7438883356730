import axios from 'axios';
import router from '@/router';

const billingModule = {
  state: {},
  mutations: {},
  actions: {
    async buyRuns({ rootState }: { rootState: any }, details: { 
      orgName: string,
      tier: string,
      runs: number
    }) {
      try {
        // Generate id for new org
        const orgId = `${Math.floor(Math.random() * 1000000000)}`

        let response = await axios({
          url: 'https://api.pluralith.com/v1/billing/checkout',
          // url: 'http://localhost:8080/v1/billing/checkout',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            tier: details.tier,
            runs: details.runs,
            orgName: details.orgName,
            orgId: `${orgId}`,
            creatorId: `${rootState.user.id}`,
            cancelUrl: `https://app.pluralith.com/#${router.currentRoute.value.fullPath}?createOrgId=${orgId}&createStatus=canceled`,
            successUrl: `https://app.pluralith.com/#/orgs/${orgId}?createOrgId=${orgId}&createStatus=succeeded`
          }
        })

        window.location = response.data.data.url
      } catch(error: any) {
        console.error(error)
        return 400
      }
    },
    async manageBilling({ rootState }: { rootState: any }) {
      try {
        let response = await axios({
          url: 'https://api.pluralith.com/v1/billing/manage/portal',
          // url: 'http://localhost:8080/v1/billing/manage/portal',
          method: 'POST',
          headers: { authorization: "Bearer " + rootState.user.apiKey },
          data: {
            orgId: `${rootState.org.id}`,
            customerId: `${rootState.org.plan.customerId}`
          }
        })

        window.location = response.data.data.url
      } catch(error: any) {
        console.error(error)
        return 400
      }
    }
  },
  getters: {}
}

export default billingModule